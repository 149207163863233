.dataTableTag {
    border-radius: 10px;
}

.result_left_5 {
    background-color: #000000;
    color: #FFFFFF;
}

.result_right_5 {
    background-color: #FD7F01;
}

.result_5_0 {
    background-color: #628DF5;
}

.result_5_5 {
    background-color: #FFFF55;
}

.result_default {
    background-color: #000000;
}

.pair_winner {
    background-color: #A3CCFA;
}

.pair_fatality {
    background-color: #FBA7A2;
}

.pair_brutality {
    background-color: #8DD98D;
}

.coefGroup_SP_winner {
    background-color: #C6B199;
}

.duration_min_avg {
    background-color: #AAFFAA;
}

.duration_min {
    background-color: #00AA00;
}

.duration_max_avg {
    background-color: #44b4ff;
}

.duration_max {
    background-color: #0060dd;
    color: #FFFFFF;
}

.coefTotalF_passed {
    background-color: #FBA7A2;
}

.coefTotalB_passed {
    background-color: #8DD98D;
}

.commonTotal_passed {
    background-color: #A3CCFA;
}

.fw_passed {
    background-color: #0060dd;
    color: #FFFFFF;
}
