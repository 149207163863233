:root {
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  height: 100vh;
  width: 100%;
  --sidebar-width: 5rem;
  --sidebar-opened-width: 15rem;
  --navbar-height: 7.5%;
  --padding-custom: 1rem;

  --nav-item-height: 10%;

  --sidebar-mobile-size: 3.5rem;

  --text-primary: #404040;
  --text-secondary: #0072ea;
  --bg-primary: #fbfdff;
  --bg-secondary: #e7f1fd;

  --stuff-color: #0072ea;

  --scroll-track: #e7f1fd;
  --scroll-thumb: #0072ea;

  --transition-speed: 400ms;
}

html[data-theme="dark"] {
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;

  --stuff-color: #df49a6;

  --scroll-track: #1e1e24;
  --scroll-thumb: #6649b8;
}

body {
  width: 100%;
  height: 100%;
  color: black;
  background-color: white;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: var(--scroll-track);
}

body::-webkit-scrollbar-thumb {
  background: var(--scroll-thumb);
}

h1, h2, h3, h4 {
  margin: 0;
  color: var(--text-primary) ;
}

* ::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-track);
}

*::-webkit-scrollbar-corner {
  background-color: var(--scroll-track)
}

*::-webkit-scrollbar-thumb {
  background: var(--scroll-thumb);
}
